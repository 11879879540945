<template>
  <div id="app">
    <div class="page">
      <SpotlightShine />
      <div class="content">
        <h1>The Spotee Connect service is no longer available</h1>
        <h3>
          It has been replaced with
        </h3>
        <a href="https://www.stockopedia.com/en-au/"
          ><img class="logo" src="./assets/bits/stockopedia.png" alt="stockopedia"
        /></a>
        <h4>
          we encourage you to visit that website
          <a href="https://www.stockopedia.com/en-au/">https://www.stockopedia.com/en-au/</a>
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import SpotlightShine from "@/components/atoms/SpotlightShine.vue";
export default {
  name: "App",
  components: {
    SpotlightShine
  }
};
</script>

<style lang="scss">
@import "@/style/app.scss";
@import "@/style/_core.scss";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: auto; //IE 11 fix for flexbox support
  @supports (height: 100%) {
    height: 100%;
  }
  & > * {
    flex-grow: 1;
  }

  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  color: $color-black;
  background: $color-background;

  .page {
    height: 100%;
    @include flexCentered(column);
    color: $color-white;
    background-color: $color-blue;
    background-image: radial-gradient(rgba($color-blue, 0) 30%, rgba($color-blue, 1)), url("./assets/bits/golf.png");
    background-position: center;

    .content {
      z-index: z("front");
      @include maxWidth($size-breakpoint-medium);
      text-align: left;
      padding: addSpace(6) addSpace(4);

      h1 {
        margin: addSpace(4) 0;
        font-weight: 300;
      }
      h4 {
        text-align: right;
      }
      .logo {
        width: 100%;
        border-radius: 30px;
        text-decoration: none;
        box-shadow: 0 0 40px -10px $color-black;
      }
      a {
        color: $color-primary;
        font-weight: 600;
      }
    }
  }
}
</style>
